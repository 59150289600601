<template>
  <div class="box">
    <img @click="
        $router.push({ path: '/course', query: { code: route.query.code } })
      " src="https://koc.lixingxiaoshuo.com/img/banner.png"
      class="swiper-img" />
    <img src="@/assets/img/logo.png" class="box-img" alt="logo" />
    <van-form class="form" @submit="yzmTest">
      <van-field class="setBorder" v-model="form.name" :border="false"
        placeholder="请填写您的姓名" :rules="[
          {
            required: true,
            message: '请填写您的姓名'
          }
        ]" />
      <van-field class="setBorder" v-model="form.wechat" :border="false"
        placeholder="请正确填写您的微信ID，方便确认打款信息" :rules="[
          {
            required: true,
            message: '微信号不合法'
          }
        ]" />
      <!-- 输入手机号，调起手机号键盘 -->
      <van-field class="setBorder" v-model="form.phone" type="tel" clearable
        :border="false" placeholder="请填写手机号" :rules="[
          {
            required: true,
            validator: phoneTest,
            message: '请填写合法手机号'
          }
        ]" />
      <div class="yzm-box">
        <van-field class="setBorder" v-model="form.verificationCode"
          type="digit" :border="false" maxlength="6" placeholder="请输入短信验证码"
          :rules="[
            {
              required: true,
              message: '请填写短信验证码'
            }
          ]" />
        <van-button class="yzm-btn" :disabled="!yzmBtnFlag" @click="sendYzm">{{
          yzmText
        }}</van-button>
      </div>
      <van-field class="setBorder" :border="false" type="digit"
        :readonly="readonlyFlag" v-model="form.invitationCode"
        placeholder="请输入顾问邀请码" :rules="[
          {
            required: true,
            message: '请输入顾问邀请码'
          }
        ]">
        <template #button>
          <span @click="$router.push('/contact')"
            style="color: #fe774c; font-weight: bold">获取顾问邀请码</span>
        </template>
      </van-field>
      <van-button class="submit" native-type="submit"> 注册 </van-button>
      <router-link to="/login" v-slot="{}">
        <div class="jumpLogin van-hairline--bottom">
          已有账号，马上<span style="font-weight: bold">登录</span>
        </div>
      </router-link>
      <div class="tips">
        达人平台免费注册，不会向达人收取任何费用，如遇收费问题，可以联系平台工作人员咨询！
      </div>
    </van-form>
  </div>
</template>

<script setup>
import { Toast } from 'vant'
import { ref, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { register, sendVerificationCode } from '@/api/login.js'
const route = useRoute()
const router = useRouter()
let yzmText = ref('获取验证码')
let yzmBtnFlag = ref(true)
let readonlyFlag = ref(false)

import $store from '@/store/index'
const form = reactive({
  name: '',
  phone: '',
  verificationCode: '',
  invitationCode: ''
})
// 校验函数返回 true 表示校验通过，false 表示不通过
const phoneTest = val => /^1?[0-9]{10}$/.test(val)
// 校验函数可以返回 Promise，实现异步校验,在这里登录并校验验证码
const yzmTest = async val => {
  let res = await register(form)
  $store.dispatch('setToken', res)
  router.push('/task')
}

// 发送验证码
async function sendYzm() {
  if (!phoneTest(form.phone)) {
    return Toast('手机号不合法')
  }
  await sendVerificationCode({
    phone: form.phone,
    sendChannel: 'reg'
  })
  Toast('发送成功')
  yzmText.value = 60
  yzmBtnFlag.value = false
  let st1 = setInterval(() => {
    yzmText.value--
  }, 1000)
  setTimeout(() => {
    window.clearInterval(st1)
    yzmText.value = '获取验证码'
    yzmBtnFlag.value = true
  }, 59000)
}
// 获取链接中带的邀请码
onMounted(() => {
  form.invitationCode = route.query.code
  if (route.query.code) {
    readonlyFlag.value = true
  }
})
</script>
<style scoped lang="less">
.box {
  min-height: 100%;
  padding: 30px 34px;
  background: white;
  .box-img {
    width: 750px;
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .form {
    width: 100%;
    .setBorder {
      padding: 0px 0px 36px;
    }
    .yzm-box {
      display: flex;
      justify-content: space-between;
      align-items: top;
      .yzm-btn {
        background: #fe774c;
        width: 310px;
        height: 80px;
        border-radius: 10px;
        color: white;
        margin-left: 36px;
        margin-top: -3px;
        letter-spacing: 2px;
        padding: 0px 20px;
      }
    }
    .submit {
      width: 100%;
      background: #fe774c;
      border-radius: 10px;
      color: white;
      letter-spacing: 4px;
      margin-top: 35px;
      font-size: 34px;
    }
    .jumpLogin {
      display: inline-block;
      letter-spacing: 4px;
      color: #fe774c;
      margin: 40px 0px;
      padding-bottom: 6px;
      font-size: 28px;
    }
    .tips {
      margin-top: 0px;
      text-align: left;
      font-size: 28px;
      color: #999;
    }
  }
  .swiper-img {
    width: 100%;
    height: 110px;
    border-radius: 6px;
    object-fit: cover;
  }
}
.van-hairline--bottom::after {
  border-color: #fe774c;
}
/deep/ .van-field__body {
  border: 2px solid #d6d6d6 !important;
  padding: 12px 24px;
  border-radius: 10px;
}
</style>
